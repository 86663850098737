import React, { useEffect, useState } from 'react';
import Error from "../../../Error/Error";
import classes from "../../Gender/Gender.module.scss";
import Radio from "../../../UI/Radio/Radio";
import { connect } from "react-redux";
import { setShortTermLosses, setShortTermLossesSpouse } from "../../../../store/actions/investorProfileActions";

const ShortTermLossesSpouseComp = ( props ) => {
    const [checked, setChecked] = useState('')
    const [error, setError] = useState(false)

    const radios = [
        {
            value: 'Yes'
        },
        {
            value: 'No'
        }
    ]

    const changeValHandler = (e) => {
        const val = e.target.value
        setChecked(val)
        props.setShortTermLossesSpouse(val)
        props.setShortTermLosses()
        //props.resetLongTermRecoveryPeriod()

    }


    useEffect(() => {
        setChecked(props.shortTermLossesSpouse)
        setError(!props.shortTermLossesSpouse)
    }, [props.shortTermLossesSpouse, error])

    return (
        <React.Fragment>
            {
                error &&
                <Error
                    message='Selection required'
                    style={{
                        left:'50%',
                        transform: 'translateX(-50%)',
                        marginLeft:0
                    }}
                />
            }

            {
                radios.map((radio, index) => {
                    return (
                        <div key={index} className = {classes.ContentRow__card__radio}>
                             <Radio
                                 changed={changeValHandler}
                                 name='shortTermLosesSpouse'
                                 value={radio.value}
                                 isSelected={ checked === radio.value }
                             />
                         </div>
                    )
                })
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        shortTermLossesSpouse: state.investorProfile.shortTermLossesSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setShortTermLossesSpouse: (val)=> dispatch(setShortTermLossesSpouse(val)),
        setShortTermLosses: ()=> dispatch(setShortTermLosses()),
        //resetLongTermRecoveryPeriod: ()=> dispatch(resetLongTermRecoveryPeriod())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortTermLossesSpouseComp);